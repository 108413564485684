.Header-top-section {
  background-color: rgba(76, 3, 138, 1);
}

.Header-top-section a {
  color: white;
}

.Header-top-section-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 5px 0px 5px 0px;
  justify-content: space-around;
}

.Header-top-section-wrap {
  width: 100%;
  /* max-width: 1160px; */
  margin: 0 auto;
  gap: 25%;
}
@media screen and (max-width: 540px) {
  .first-strengths-container-row {
    flex-direction: column !important;
  }
}
@media screen and (max-width: 1140px) {
  .services-description {
    flex-direction: column !important;
  }
}
@media screen and (max-width: 770px) {
  .menu{
    margin: 30px 0px 0px 0px !important;
  }
  .Header-bottom-section-row {
    display: flex;
    flex-wrap: wrap;
    padding: 15px 0px 15px 0px;
    flex-direction: column;
    align-items: center;
}
}
.Header-top-section-col-start {

  justify-content: flex-start;
  align-items: center;
}

.Header-top-section-col-end {

  justify-content: flex-end;
  align-items: center;
}

.Header-bottom-section-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 15px 0px 15px 0px;
}

.Header-bottom-section-wrap {
  width: 100%;
  max-width: 1160px;
  margin: 0 auto;
}

.Header-bottom-section-col-start {
  flex-basis: 50%;
  justify-content: flex-start;
  align-items: center;
}

.Header-bottom-section-col-end {
  flex-basis: 50%;
  justify-content: flex-end;
  align-items: center;
}

.divider-wrap {
  width: 1px;
  height: 16px;
  background: rgba(255, 255, 255, 0.2);
  margin: 0px 15px 0px 0px;
}

.social-icons a {
  margin-right: 15px;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: none;
}

.logo {
  max-height: 80px;
}

nav {
  display: block;
}

nav ul,
nav ol {
  list-style: none !important;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

.menu {
  margin: 30px 0px 0px 200px;
}

.menu>ul>li {
  margin: 0px 5px 0px 5px;
}

.menu ul li {
  float: left;
  position: relative;
  margin-bottom: 0;
  line-height: 1.5;
}

.menu>ul>li>a {
  font-family: "Proxima Nova", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  font-weight: 400;
  text-transform: none;
  font-size: 18px;
  padding: 10px;
  text-decoration: none;
}

.menu a {
  color: #000000;
}

.row-inner {
  color: #ffffff;
  text-align: center;
  /* font-family: "Proxima Nova",-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen-Sans,Ubuntu,Cantarell,'Helvetica Neue',sans-serif; */

  font-family: "Hind", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;

}

/* .main-section {
  background-image: url(assets/architecture-bridge-buildings-756908.jpg);
}

.about-section {
  background-image: url(assets/adults-analysis-brainstorming-1661004.jpg);
}

.services-section {
  background-image: url(assets/adult-business-businessman-1061588.jpg);
}

.contact-section {
  background-image: url(assets/accounting-achievement-aerial-10435066.png);
} */

.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.App-link {
  color: #61dafb;
}


.first-strengths-wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 450px;
  margin: 30px 0px 30px 0px;
}

.first-strengths-heading {
  display: flex;
  flex-direction: column;
  margin: 40px 0px 60px 0px;
}

.first-strengths-container-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;

}

.first-strengths-container-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

}

.first-strengths {
  display: flex;
  align-items: center;
  padding: 30px 15px 30px 15px;
  width: 150px;


}

.first-strengths>span {
  padding: 0px 10px 0px 10px;
}

.img-icon-simple {
  color: #251351
}

.about-container {
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px 0px 20px 0px;
}

.about-heading {
  font-family: "Proxima Nova", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  text-transform: none;
  letter-spacing: -0.01em;
  line-height: 30px;
  color: rgba(49, 50, 51, 1);
  padding-bottom: 20px;
}

.about-text {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-family: "Hind", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 17px;
  text-transform: none;
  letter-spacing: 0;
  line-height: 30px;
}

.about-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  background-color: rgba(97, 124, 255, 0.1);
  border-width: 1px 1px 1px 1px;
  width: 60%;
  margin: 10px 10px 10px 10px;
  padding: 50px 65px 50px 65px;
}

.h4 {
  color: #251351;
  padding: 50px 0px 20px 0px;
}

.h1 {
  font-family: "Proxima Nova", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 34px;
  text-transform: none;
  letter-spacing: -0.003em;
  line-height: 46px;
  color: rgba(49, 50, 51, 1);
}

.h3 {
  font-family: "Proxima Nova", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 34px;
  text-transform: none;
  letter-spacing: -0.003em;
  line-height: 46px;
  color: rgba(49, 50, 51, 1);
}

.h5 {
  font-family: "Proxima Nova", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  text-transform: none;
  letter-spacing: -0.01em;
  line-height: 30px;
  color: rgba(49, 50, 51, 1);
}

.services-section {
  display: flex;
  flex-direction: column;
}

.services-text {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: space-around;
}

.services-heading {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.services-desc-heading {
  padding: 5px 0px 30px 0px;
  font-size: 16px;
  font-weight: 500;
}

.services-description {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
  align-content: space-around;
  text-align: justify;
  gap: 120px;
  height: auto;
  margin: 80px 0px 80px 0px;

}

.services-desc-field {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  max-height: 350px;
  width: 300px;
  height: auto;
}

.purple-banner-img {

  background-attachment: scroll;
  background-position: center;
  background-size: cover;
  position: relative;
  height: 280px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.image-container {
  position: relative;

}

.image-container .after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  color: #FFF;
}

.image-container .after {
  display: block;
  background: rgba(76, 3, 138, 0.86);
}

.purple-banner-heading {
  display: flex;
  flex-direction: column;

  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.infoMessage{
  text-align: center;
  padding: 20px 0px 10px 0px;
  font-weight: 400;
}
.purple-banner-text {
  z-index: 9999;
  color: white;
  text-align: center;
  position: relative;


  font-size: 36px;
  line-height: 48px;
}

.purple-banner-subtext {
  z-index: 9999;
  color: white;
  text-align: center;
  position: relative;
  /* top: 44%; */
  padding-top: 12px;
}

.contact-wrapper {
  display: flex;
  flex-direction: row;
  align-content: space-around;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 30px 0px 30px 0px;
  width: 100%;
  gap: 30px;
}

.contact-field {
  height: 100px;
  width: 250px;
  background: rgba(245, 246, 250, 1);
  border-radius: 5px;
  color: white
}

.contact-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.contact-link {
  color: rgb(255, 255, 255);
  text-decoration: none;
  /* no underline */
  width: 50px;
  display: block;
  height: 50px;
}

.contact-section2 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-width: 98%;
  align-items: center;
}

.contact-section2-heading {
  font-family: "Proxima Nova", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 26px;
  text-transform: none;
  letter-spacing: 0;
  line-height: 38px;
  color: rgba(49, 50, 51, 1);
}

.contact-section2-span {
  font-family: "Proxima Nova", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 26px;
  text-transform: none;
  letter-spacing: 0;
  line-height: 38px;
  color: rgba(49, 50, 51, 1);
}

.contact-spacer {
  background-color: rgba(76, 3, 138, 1);
  border-radius: 50%;
  text-align: center;
  width: 50px;
  height: 50px;
  position: relative;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.contact-icon {
  text-align: center;
  font-size: 22px;
  position: relative;
  top: 16%;
}


.contact-form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  align-content: space-around;
  margin: 50px;
}

.form-label {
  display: block;
  font-weight: 700;
  font-size: 16px;
  float: none;
  line-height: 1.3;
  margin: 10px 0 10px 0;
  padding: 0;
  word-break: break-word;
  word-wrap: break-word;

}

.form-field {
  padding: 15px 0 15px 10px;
  border: 1px solid transparent;
  -webkit-transition: border-color .3s;
  -o-transition: border-color .3s;
  transition: border-color .3s;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 4px 0 rgb(0 0 0 / 16%);
  box-shadow: inset 0 0 4px 0 rgb(0 0 0 / 16%);
  height: auto;
  background-color: #f5f6fa;
  width: 300px;
  height: 5px;
  max-width: 280px;
}

form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  text-align: left;

}

.form-message {

  height: 100px;
}

.general-button {
  /* height: 30px;
 
  border-radius: 10px;
  background-color: white; */
  width: 200px;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: #1890ff;
  color: #fff;
  padding: 15px 25px;
  border: 0;
  margin: 15px 0 0;


}

.general-button:hover {
  /* background-color: aliceblue; */
  border: 1px solid #ccc;
  cursor: pointer;

}

.contact-form-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  min-width: 250px;
}

.contact-info-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 700;
  line-height: 2.0;
  padding: 0;
  word-break: break-word;
  word-wrap: break-word;
}

.contact-info {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.contact-link-address {
  color: rgb(255, 255, 255);
  text-decoration: none;
  /* no underline */
  width: 45px;
  display: block;
  height: 45px;
}

.contact-spacer-address {
  background-color: rgba(76, 3, 138, 1);
  border-radius: 50%;
  text-align: center;
  width: 45px;
  height: 45px;
  position: relative;
  top: 50%;

  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.contact-icon-address {
  text-align: center;
  font-size: 19px;
  position: relative;
  top: 19%;
}

.contact-address-text {
  color: gray
}

.footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  margin: 30px;
  padding-top: 30px;
  margin-left: 0px;
}

.contact-icon-footer {
  margin: 15px;
  color: gray
}

/* 
.tatsu-shape-divider {
  position: relative;
  overflow: hidden;
  line-height: 0;
  z-index: 2;
  pointer-events: none;

  
}

.tatsu-bottom-divider {
  height: 130px;
  color: rgba(255,255,255,1);
  z-index: 9999;
} */